import events from '~/utils/events'

let messagesConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'messages-client',

  /**
   * Mixins
   * @type {Object}
   */
  mixins: {
    events,
    cookieMessage: [],
    config: []
  },

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED MESSAGES')

    this.attachGlobalEventHandlers()
  },

  /**
   * Footer event handler
   * @void
   */
  attachGlobalEventHandlers () {
    const MESSAGE_WRAPPER = this.data().component
    const BANDEAU = MESSAGE_WRAPPER.find('.messages-bandeau')
    const MODAL = MESSAGE_WRAPPER.find('.messages-modal')
    const MESSAGES_BANDEAU = BANDEAU.find('.message')
    const MESSAGES_MODAL = MODAL.find('.message')

    /**
     * get cookie messages
     * @type {Object}
     */
    this.mixins.cookieMessage = this.mixins.events.getCookie('henaff_messages')

    /**
     * Windows on load event
     */
    $(window).on(
      'load',
      () => {
        if (BANDEAU.hasClass('d-none')) {
          BANDEAU.removeClass('d-none')
        }

        if (MODAL.hasClass('d-none')) {
          MODAL.removeClass('d-none')
        }

        if (this.mixins.cookieMessage.length > 0) {
          this.checkCookie()
        }
      }
    )

    /**
     * Windows on load event
     */
    MESSAGES_MODAL.each(
      (_index, _el) => {
        let _close = $(_el).find('button')

        _close.on(
          'click',
          () => {
            this.initCookie(_el)

            $(_el).animate(
              {
                left: 320,
                opacity: 'hide'
              },
              { duration: 500 }
            )
          }
        )
      }
    )

    MESSAGES_BANDEAU.each(
      (_index, _el) => {
        let _close = $(_el).find('button')

        _close.on(
          'click',
          () => {
            this.initCookie(_el)

            $(_el).animate(
              { opacity: 'hide' },
              { duration: 500 }
            )
          }
        )
      }
    )
  },

  /**
   * Cookie management
   */
  initCookie (_message) {
    let _id = $(_message).data('id')
    let _type = $(_message).data('type')
    let _expires = new Date()

    _expires.setTime(_expires.getTime() + (365 * 24 * 60 * 60 * 1000))

    if (this.mixins.cookieMessage.length > 0) {
      this.mixins.config = Object.keys(this.mixins.cookieMessage)
        .map(
          _key => {
            return this.mixins.cookieMessage[_key]
          }
        )
    }

    switch (_type) {
      // once per day
      case 2: {
        let _data = {
          id: _id,
          type: _type,
          date: Date.now()
        }

        if (this.mixins.config.length > 0) {
          $.each(
            this.mixins.config,
            (_index, _config) => {
              if (_config.id === _id) {
                this.mixins.config[_index] = _data
              } else {
                this.mixins.config.push(_data)
              }
            }
          )
        } else {
          this.mixins.config.push(_data)
        }

        break
      }

      // once ever
      case 3: {
        this.mixins.config.push(
          {
            id: _id,
            type: _type,
            isShow: true
          }
        )
        break
      }

      // always
      default: {
        this.mixins.config.push(
          {
            id: _id,
            type: _type
          }
        )
        break
      }
    }

    // Create cookie
    this.mixins
      .events
      .setCookie('henaff_messages', JSON.stringify(this.mixins.config), 365)
  },

  /**
   * Check cookie
   * void
   */
  checkCookie () {
    const MESSAGE_WRAPPER = this.data().component

    $.each(
      this.mixins.cookieMessage,
      (_index, _config) => {
        let _message = MESSAGE_WRAPPER.find(`.message-${_config.id}`)

        switch (_config.type) {
          // once per days
          case 2: {
            let _now = new Date()
            let _dateLast = new Date(_config.date)

            if (_now.toDateString() == _dateLast.toDateString()) {
              _message.hide()
            }
            break
          }

          // once ever
          case 3: {
            if (_config.isShow) {
              _message.hide()
            }
            break
          }
        }
      }
    )
  }
}

/**
 * Module export
 */
export default messagesConfiguration
