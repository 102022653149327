/**
 * Global events manager
 */
import 'readmore-js'
import { EVENTS } from '~/config/constant'
/**
 * Events manager configuration
 * @type {Object}
 */
let eventsConfiguration = {
  /**
   * Component data
   * @return {Object}
   */
  screen: {
    width: 0,
    height: 0
  },

  /**
   * Component created
   * @return {Void}
   */
  created () {
    this.setupHandlers()
    this.setWindowSizes()
  },

  /**
   * Set up handler
   * @return {Void}
   */
  setupHandlers () {
    $(window).on(
      'resize load',
      () => {
        this.setWindowSizes()
      }
    )
  },

  /**
   * Set window sizes
   */
  setWindowSizes () {
    this.screen.width = window.innerWidth
    this.screen.height = window.innerHeight

    $(window).trigger(EVENTS.WINDOW_RESIZE, [this.screen.width, this.screen.height])
  },

  /**
   * Check if element is in viewport
   * @param  {DOMElement}  _el
   * @return {Boolean}
   */
  isInViewport (_el) {
    let _rect = _el.getBoundingClientRect()

    return (
      _rect.bottom >= 0 &&
      _rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      getComputedStyle(_el).display !== 'none'
    )
  },

  /**
   * Check if element is overflowing
   * @param  {DOMElement}  _el
   * @return {Boolean}
   */
  isOverflowing (_el) {
    let _rect = _el.getBoundingClientRect()
    let _isRight = (
      _rect.right >= 0 &&
      _rect.right >= (document.documentElement.clientWidth || window.innerWidth) &&
      getComputedStyle(_el).display !== 'none'
    )
    let _isleft = (
      _rect.left >= 0 &&
      _rect.left >= (document.documentElement.clientWidth || window.innerWidth) &&
      getComputedStyle(_el).display !== 'none'
    )

    return {
      left: _isleft,
      right: _isRight
    }
  },

  /**
   * get cookie
   * @param  {String} _name
   */
  getCookie (_name) {
    const REGEX = new RegExp(`(?:(?:^|.*;\\s*)${_name}\\s*=\\s*([^;]*).*$)|^.*$`)
    const COOKIE = document.cookie.replace(REGEX, '$1')

    if (!COOKIE) {
      return {}
    }

    return JSON.parse(COOKIE) || {}
  },

  /**
   * get cookie
   * @param  {String} _name
   */
  setCookie (_name, _value, _date) {
    let _expires = new Date()

    _expires.setTime(_expires.getTime() + (_date * 24 * 60 * 60 * 1000))

    let _cookie = [
      `${_name}=`,
      _value,
      '; domain=.',
      window.location.host.toString(),
      '; expires=',
      _expires.toUTCString(),
      '; path=/;'
    ]

    document.cookie = _cookie.join('')
  },

  /**
   * Smooth Scroll
   * @param  {String} _hash
   */
  smoothScroll (_hash) {
    const BODY = $('body')
    const STICKY = BODY.find('.sticky-nav')
    const HEADER = BODY.find('.main-header')
    const OFFSET_HEADER = (STICKY.length > 0) ? STICKY : HEADER

    if (OFFSET_HEADER.length > 0) {
      if (_hash !== '') {
        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (500) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate(
          { scrollTop: ($(_hash).offset().top - (OFFSET_HEADER.get(0).offsetHeight + 20)) },
          500,
          () => {
            // Add hash (#) to URL when done
            window.location.href.split('#')[0]
          }
        )
      }
    }
  },

  /**
   * Readmore Text
   * @param  {Object} _element
   */
  readmoreText (_element, _isDestroy, _height) {
    if (_isDestroy) {
      $(_element).readmore('destroy')
    } else {
      $(_element).readmore(
        {
          speed: 75,
          collapsedHeight: !_height ? 145 : _height,
          moreLink: '<a href="#">Afficher la suite</a>',
          lessLink: '<a href="#">Afficher moins</a>',
          blockCSS: 'display: inline-block;'
        }
      )
    }
  }
}

/**
 * Module export
 */
export default eventsConfiguration
