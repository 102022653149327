import 'bootstrap/js/dist/collapse'
import { EVENTS } from '~/config/constant'
import events from '~/utils/events'

let productListConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'product-list',

  /**
   * Mixins
   * @type {Object}
   */
  mixins: events,

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`body`) }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED PRODUCT_LIST')

    this.attachGlobalEventHandlers()
  },

  /**
   * Footer event handler
   * @void
   */
  attachGlobalEventHandlers () {
    const BODY = this.data().component
    const EXCERPT = BODY.find('#category-description .editor-content')

    prestashop.on(
      'updateProductList',
      data => {
        this.updateProductListDOM(data)

        BODY.trigger(EVENTS.DOM_RELOAD)
      }
    )

    // Selectbox filter
    BODY.on(
      'change',
      '#select-sort-order',
      event => {
        let _urlsearch = $(event.currentTarget).val()

        prestashop.emit(
          'updateFacets',
          _urlsearch
        )
      }
    )

    // facet filter
    BODY.on(
      'change',
      '#search_filters input[data-search-url]',
      event => {
        prestashop.emit(
          'updateFacets',
          this.parseSearchUrl(event)
        )
      }
    )

    BODY.on(
      'click',
      '.js-search-link',
      event => {
        event.preventDefault()

        prestashop.emit(
          'updateFacets',
          this.checkPaginate(event)
        )
      }
    )

    // Clear all filter
    BODY.on(
      'click',
      '.js-search-filters-clear-all',
      event => {
        prestashop.emit(
          'updateFacets',
          this.parseSearchUrl(event)
        )
      }
    )

    BODY.on(
      'click',
      '#search_filter_toggler',
      event => {
        let _toggler = $(event.currentTarget)
        let _facetWrapper = $('#search_filters_wrapper').find('.facet-fliters-content')

        _toggler.toggleClass('show-facet')
        _facetWrapper.removeClass('d-none')
        _facetWrapper.collapse('toggle')
      }
    )

    // Readmore text
    // Short description readmore
    $(window).on(
      EVENTS.WINDOW_RESIZE,
      () => {
        this.mixins.readmoreText(EXCERPT, false, 90)
      }
    )
  },

  /**
   * Check if paginate url and scroll top
   * @param  {Objectt} _event
   * @return {String}
   */
  checkPaginate (_event) {
    let _search = $(_event.target)
    let _pagination = _search.parents('.pagination')

    if (_pagination.length > 0) {
      window.scrollTo(0, 0)
    }

    return $(_event.target).closest('a')
      .get(0).href
  },

  /**
   * Parse facet search Url
   * @param  {Object} _event
   * @return {String}
   */
  parseSearchUrl (_event) {
    let _target = _event.target

    if (_target.dataset.searchUrl !== undefined) {
      return _target.dataset.searchUrl
    }

    if ($(_target).parent()[0].dataset.searchUrl === undefined) {
      throw new Error('Can not parse search URL')
    }

    return $(_target).parent()[0].dataset.searchUrl
  },

  /**
   * Update product list DOM after ajax request
   * @param  {Object} data
   * @vois
   */
  updateProductListDOM (data) {
    const FACET = $('#search_filters')
    const ACTIVE_FACET = $('#js-active-search-filters')
    const FILTER = $('#js-product-list-top')
    const PRODUCT_LIST = $('#js-product-list')
    const PAGINATE = $('#js-product-list-bottom')
    const CATEGORY = $('#js-product-list-header')

    FACET.replaceWith(data.rendered_facets)
    ACTIVE_FACET.replaceWith(data.rendered_active_filters)
    FILTER.replaceWith(data.rendered_products_top)
    PRODUCT_LIST.replaceWith(data.rendered_products)
    PAGINATE.replaceWith(data.rendered_products_bottom)

    if (typeof data.rendered_products_header !== 'undefined' && data.rendered_products_header) {
      CATEGORY.replaceWith(data.rendered_products_header)
    }
  }
}

/**
 * Module export
 */
export default productListConfiguration
