let shareSocialConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'social-sharing',

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED SHARE BUTTON')

    this.attachGlobalEventHandlers()
  },

  /**
   * Footer event handler
   * @void
   */
  attachGlobalEventHandlers () {
    const SHARE_BUTTONS = this.data().component
    const BUTTONS = SHARE_BUTTONS.find('a')

    BUTTONS.each(
      (_index, _el) => {
        $(_el).on(
          'click',
          event => {
            let _link = $(event.currentTarget)

            if (_link.data('trk-social') !== 'mail') {
              event.preventDefault()

              this.sharePopup(_link, 500, 400, true)
            }
          }
        )
      }
    )
  },

  sharePopup (_link, _width, _height, _isResize) {
    let _title = ((typeof _link.attr('title') !== 'undefined') ? _link.attr('title') : 'Partager sur les réseaux')
    let _resize = (_isResize ? 'yes' : 'no')
    let _left = (document.documentElement.clientWidth / 2) - (_width / 2)
    let _top = (document.documentElement.clientHeight / 2) - (_height / 2)
    let _params = `left=${_left},top=${_top},width=${_width},height=${_height},resizable=${_resize}`

    window.open(
      _link.attr('href'),
      _title,
      _params
    ).focus()
  }
}

/**
 * Module export
 */
export default shareSocialConfiguration
