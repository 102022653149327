import 'bootstrap/js/dist/collapse'
import events from '~/utils/events'

let headerConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'main-header',

  /**
   * Mixins
   * @type {Object}
   */
  mixins: events,

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @vois
   */
  created () {
    console.log('CREATED HEADER')

    this.attachGlobalEventHandlers()
  },

  /**
   * Header event handler
   * @void
   */
  attachGlobalEventHandlers () {
    this.languageDropdown()
    this.toggleMobileNavigation()
    this.stickyNavigation()
    this.navigationEventHandler()
  },

  /**
   * Language selector dropdown
   * @void
   */
  languageDropdown () {
    const HEADER = this.data().component
    const LANG_SELECTOR = HEADER.find('.language-selector')
    const LANG_CURRENT = LANG_SELECTOR.find('.current-language')

    LANG_CURRENT.on(
      'click',
      event => {
        let _currentSelector = $(event.currentTarget).parents('.language-selector')

        _currentSelector.toggleClass('open')
      }
    )
  },

  /**
   * Mobile navigation
   * @void
   */
  toggleMobileNavigation () {
    const HEADER = this.data().component
    const NAV = HEADER.find('.mobile-nav .mainmenu')
    const OPEN = HEADER.find('.header-nav .burger-toggle')
    const CLOSE = NAV.find('.menu-close')

    OPEN.on(
      'click',
      () => {
        NAV.addClass('menu-show')
      }
    )

    CLOSE.on(
      'click',
      () => {
        NAV.removeClass('menu-show')
      }
    )
  },

  /**
   * Navigation sub menu event handler
   * @returns {Generator<*, void, *>}
   */
  navigationEventHandler () {
    const HEADER = this.data().component
    const STICKY_NAV = HEADER.find('.sticky-nav')
    const DESKTOP_NAV = HEADER.find('.desktop-nav')

    STICKY_NAV.on(
      'mouseenter',
      '.mainmenu .menu-list .has-submenu',
      event => {
        let _target = $(event.currentTarget)

        this.positioningSubNav(_target)
      }
    )

    DESKTOP_NAV.on(
      'mouseenter',
      '.mainmenu .menu-list .has-submenu',
      event => {
        let _target = $(event.currentTarget)

        this.positioningSubNav(_target)
      }
    )
  },

  /**
   * Positionning submenu when overflowing
   */
  positioningSubNav (_nav) {
    let _subMenu = $(_nav).find('.submenu')

    if (_subMenu.length > 0) {
      let _isOverflow = this.mixins.isOverflowing(_subMenu.get(0))

      if (_isOverflow.right && _isOverflow.left) {
        _subMenu.removeAttr('style')
      }

      if (_isOverflow.right) {
        _subMenu.removeAttr('style')
        _subMenu.css('right', '0')
      }

      if (_isOverflow.left) {
        _subMenu.removeAttr('style')
        _subMenu.css('left', '0')
      }
    }
  },

  stickyNavigation () {
    const HEADER = this.data().component
    const STICKY_NAV = HEADER.find('.sticky-nav')
    const DESKTOP_NAV = HEADER.find('.desktop-nav')

    if (DESKTOP_NAV.length > 0) {
      $(window).on(
        'load scroll resize',
        () => {
          let _isInview = this.mixins.isInViewport(DESKTOP_NAV.get(0))

          if (_isInview) {
            STICKY_NAV.removeClass('reveal')
          } else {
            STICKY_NAV.addClass('reveal')
          }
        }
      )
    }
  }
}

/**
 * Module export
 */
export default headerConfiguration
