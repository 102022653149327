let formConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'form',

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED FORM')

    this.attachGlobalEventHandlers()
  },

  /**
   * Footer event handler
   * @void
   */
  attachGlobalEventHandlers () {
    this.parentFocus()
    this.togglePasswordVisibility()

    $("input[name='submitMessage']").click(function (event) {
      event.preventDefault()
      // eslint-disable-next-line no-undef
      grecaptcha.execute()
    })
  },

  /**
   * Parent focus
   * @return {[type]} [description]
   */
  parentFocus () {
    $('.js-child-focus').on(
      'focus',
      event => {
        let _element = $(event.currentTarget)

        _element.closest('.js-parent-focus').addClass('focus')
      }
    )

    $('.js-child-focus').on(
      'focusout',
      event => {
        let _element = $(event.currentTarget)

        _element.closest('.js-parent-focus').removeClass('focus')
      }
    )
  },

  /**
   * Toggle Password
   */
  togglePasswordVisibility () {
    $('button[data-action="show-password"]').on(
      'click',
      event => {
        let _button = $(event.currentTarget)
        let _input = _button.closest('.input-group').children('input.js-visible-password')

        if (_input.attr('type') === 'password') {
          _input.attr('type', 'text')
          _button.addClass('active')
        } else {
          _input.attr('type', 'password')
          _button.removeClass('active')
        }
      }
    )
  }
}

/**
 * Module export
 */
export default formConfiguration
