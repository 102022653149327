import 'bootstrap/js/dist/modal'

let blockCartConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'block-cart',

  /**
   * Mixins
   * @type {Object}
   */
  mixins: { promises: [] },

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $('#blockcart-modal') }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED BLOCK_CART')

    prestashop.blockcart = prestashop.blockcart || {}

    prestashop.blockcart.showModal = html => {
      this.showBlockCartModal(html)
      this.createProductSpin()
      this.attachGlobalEventHandlers()
    }
  },

  /**
   * Override shoppingcat.js showModal
   * @param  {DOMElement} _html
   * @void
   */
  showBlockCartModal (_html) {
    let _blockCart = this.data().component

    if (_blockCart.length > 0) {
      _blockCart.find('.product-info-modal')
        .replaceWith($(_html).find('.product-info-modal'))
      _blockCart.find('.cart-table')
        .replaceWith($(_html).find('.cart-table'))
    } else {
      $('body').append(_html)
    }

    _blockCart = this.data().component

    _blockCart
      .modal('show')
      .on(
        'hidden.bs.modal',
        event => {
          prestashop.emit(
            'updateProduct',
            {
              reason: event.currentTarget.dataset,
              event: event
            }
          )
        }
      )
  },

  /**
   * Abort previous ajax request
   * @void
   */
  abortPreviousRequests () {
    while (this.mixins.promises.length > 0) {
      let _promise = this.mixins.promises.pop()

      _promise.abort()
    }
  },

  /**
   * Create quantity touchspin
   * @void
   */
  createProductSpin () {
    const PRODUCT_CART = this.data().component
    const QUANTITY_INPUT = PRODUCT_CART.find('#quantity_toggler')
    let _inputConfig = {
      buttondown_class: 'btn js-touchspin',
      buttonup_class: 'btn js-touchspin',
      min: parseInt(QUANTITY_INPUT.attr('min'), 10),
      max: 1000000
    }

    QUANTITY_INPUT.TouchSpin(_inputConfig)
  },

  /**
   * Parse blockcart event action
   * @return {Object}
   */
  parseBlockCartAction (_target, _namespace) {
    let _blockCartAction = {}

    if (_namespace === 'on.startupspin') {
      _blockCartAction = {
        url: _target.data('up-url'),
        type: 'increaseProductQuantity'
      }
    } else {
      _blockCartAction = {
        url: _target.data('down-url'),
        type: 'decreaseProductQuantity'
      }
    }

    return _blockCartAction
  },

  /**
   * Product page event handler
   * @void
   */
  attachGlobalEventHandlers () {
    const PRODUCT_CART = this.data().component
    const QUANTITY_INPUT = PRODUCT_CART.find('#quantity_toggler')

    QUANTITY_INPUT.on(
      'touchspin.on.startdownspin',
      event => {
        event.preventDefault()

        this.updateShoppingCart(event)
      }
    )

    QUANTITY_INPUT.on(
      'touchspin.on.startupspin',
      event => {
        event.preventDefault()

        this.updateShoppingCart(event)
      }
    )
  },

  /**
   * Blockcart event handler
   * @param  {Event} event
   * @void
   */
  updateShoppingCart (_event) {
    const TARGET = $(_event.currentTarget)
    const QUERY = {
      ajax: '1',
      action: 'update'
    }

    let _cartAction = this.parseBlockCartAction(TARGET, _event.namespace)

    if (typeof _cartAction === 'undefined') {
      return
    }

    this.abortPreviousRequests()

    $.ajax(
      {
        url: _cartAction.url,
        method: 'POST',
        data: QUERY,
        dataType: 'json',
        beforeSend: jqXHR => {
          this.mixins.promises.push(jqXHR)
        }
      }
    )
      .then(
        resp => {
          TARGET.val(resp.quantity)

          prestashop.emit(
            'updateCart',
            {
              reason: {
                idProduct: resp.id_product,
                idProductAttribute: resp.id_product_attribute,
                idCustomization: resp.id_customization,
                linkAction: 'add-to-cart',
                cart: resp.cart
              },
              resp: resp
            }
          )
        }
      )
      .fail(
        resp => {
          prestashop.emit(
            'handleError',
            {
              eventType: 'updateShoppingCart',
              resp: resp,
              cartAction: _cartAction.type
            }
          )
        }
      )
  }
}

/**
 * Module export
 */
export default blockCartConfiguration
