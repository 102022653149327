/**
 * Global constant
 */

export const APP = {
  /**
   * Application namespace
   */
  NAME: 'Henaff & Co'
}

export const EVENTS = {
  /**
   * App events
   */
  APP_READY: 'app-ready',

  /**
   * Window events
   */
  WINDOW_RESIZE: 'window-resize',

  /**
   * Dom events
   */
  DOM_RELOAD: 'dom-reload'
}

export const SLICK = {
  speed: 300,
  infinite: false,
  autoplay: false,
  arrows: true,
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  rows: 0,
  prevArrow: '<i class="slide-prev"></i>',
  nextArrow: '<i class="slide-next"></i>',
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1280,
      settings: { slidesToShow: 3 }
    },
    {
      breakpoint: 992,
      settings: { slidesToShow: 2 }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        arrows: false,
        centerMode: true,
        centerPadding: '120px'
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        arrows: false,
        centerMode: true,
        centerPadding: '60px'
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        arrows: false,
        centerMode: true,
        centerPadding: '30px'
      }
    }
  ]
}
