import YTPlayer from 'youtube-player'

let videoConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'video',

  /**
   * Mixins
   * @type {Object}
   */
  mixins: { player: [] },

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED VIDEO')

    this.initAboutUsPlayer()
    this.attachGlobalEventHandlers()
  },

  /**
   * Footer event handler
   * @void
   */
  attachGlobalEventHandlers () {
    const VIDEO = this.data().component

    if (VIDEO.length > 0 && this.mixins.player.length > 0) {
      VIDEO.each(
        (_index, _video) => {
          const THUMBNAIL = $(_video).find('.thumbnail')
          const PLAYER = this.mixins.player[_index]
          const PLAYER_STATE = {
            '-1': 'UNSTARDED',
            0: 'ENDED',
            1: 'PLAYING',
            2: 'PAUSED',
            3: 'BUFFERING',
            5: 'CUED'
          }

          THUMBNAIL.on(
            'click',
            () => {
              PLAYER.playVideo()
            }
          )

          PLAYER.on(
            'stateChange',
            event => {
              if (!PLAYER_STATE[event.data]) {
                throw new Error(`Unknown state (${event.data}).`)
              }

              switch (PLAYER_STATE[event.data]) {
                // Playing
                case 'PLAYING': {
                  THUMBNAIL.fadeOut(300)
                  THUMBNAIL.removeClass('video-loading')
                  THUMBNAIL.find('.icon')
                    .removeClass('spinner')
                    .addClass('play')

                  $.each(
                    this.mixins.player,
                    (_currentIndex, _player) => {
                      if (_currentIndex !== _index) {
                        _player.pauseVideo()
                      }
                    }
                  )

                  break
                }

                // Paused
                case 'PAUSED': {
                  THUMBNAIL.fadeIn(300)

                  break
                }

                // Buffering
                case 'BUFFERING': {
                  THUMBNAIL.addClass('video-loading')
                  THUMBNAIL.find('.icon')
                    .removeClass('play')
                    .addClass('spinner')

                  break
                }

                default: {
                  break
                }
              }
            }
          )
        }
      )
    }
  },

  /**
   * get youtube video ID
   * @param  {string} url
   * @return {string}
   */
  getYouTubeID (url) {
    let _url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)

    return (_url[2] !== undefined) ? _url[2].split(/[^0-9a-z_-]/i)[0] : _url[0]
  },

  /**
   * init About us player
   * @void
   */
  initAboutUsPlayer () {
    const VIDEO = this.data().component
    const PLAYER_CONFIG = {
      controls: 0,
      fullscreen: 1,
      annotations: 0,
      related: 0
    }

    if (VIDEO.length > 0) {
      VIDEO.each(
        (_i, _el) => {
          let _player = $(_el).find('.video-player > a')
          let _videoUrl = _player.attr('href')
          let _videoId = this.getYouTubeID(_videoUrl)
          let _ytplayer = YTPlayer(_player.get(0), {
            videoId: _videoId,
            playerVars: PLAYER_CONFIG
          })

          this.mixins.player.push(_ytplayer)
        }
      )
    }
  }
}

/**
 * Module export
 */
export default videoConfiguration
