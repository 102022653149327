/**
 * Prestashop javascript
 *
 * import here all js files from core or modules
 * you want to ship with main theme js bundle
 */
// core.js
// import 'core.js'

// jquery ui autocomplete
import 'jquery/ui/jquery.ui.core.min.js'
import 'jquery/ui/jquery.ui.widget.min.js'
import 'jquery/ui/jquery.ui.position.min.js'
import 'jquery/ui/jquery.ui.menu.min.js'
import 'jquery/ui/jquery.ui.autocomplete.min.js'
import 'jquery/ui/jquery.ui.mouse.min.js'

// Modules
import 'ps_shoppingcart/ps_shoppingcart.js'
import 'ps_searchbar/ps_searchbar.js'
/* import 'productcomments/views/js/jquery.rating.plugin.js'
import 'productcomments/views/js/post-comment.js'
import 'productcomments/views/js/list-comments.js'
import 'productcomments/views/js/jquery.simplePagination.js' */
import 'ps_facetedsearch/views/dist/front.js'
import 'pm_advancedpack/views/js/global-17.js'
import 'henaff_orejime/assets/orejime.js'

function docReady (fn) {
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    setTimeout(fn, 1)
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

docReady(function() {
  $(document).on('click', '.orejime-Notice-saveButton', function() {
    $('body.orejime-layer-show').removeClass('orejime-layer-show')
  })

  $(document).on('click', '.orejime-Modal-saveButton', function() {
    $('body.orejime-layer-show').removeClass('orejime-layer-show')
  })

  $(document).on('click', '.orejime-Notice-declineButton', function() {
    $('body.orejime-layer-show').removeClass('orejime-layer-show')
  })
})
