/* global $ */
$(document).ready(function () {
    var $searchWidget = $('.searchbar');
    var $searchBox    = $searchWidget.find('input[type=text]');
    var searchURL     = $searchWidget.attr('data-search-controller-url');

    $.widget('prestashop.psBlockSearchAutocomplete', $.ui.autocomplete, {
        _renderItem: function (ul, product) {
          var cover = product.cover ? $('<img src="' + product.cover.bySize.small_default.url + '" width="50" height="50">') : '';

          return $("<li>")
              .append($("<a>")
                  .append(cover)
                  .append($('<div>').addClass("text")
                    .append($("<span>").html(product.category_name).addClass("category"))
                    .append($("<span>").html(product.name).addClass("product")))

              ).appendTo(ul);
        }
    });

    $searchBox.psBlockSearchAutocomplete({
        source: function (query, response) {
            $.post(searchURL, {
                s: query.term,
                resultsPerPage: 10
            }, null, 'json')
            .then(function (resp) {
                response(resp.products);
            })
            .fail(response);
        },
        select: function (event, ui) {
            var url = ui.item.url;
            window.location.href = url;
        },
    });
});
