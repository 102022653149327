import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/collapse'
import 'select2'
import events from '~/utils/events'

$.fn.select2.amd.define(
  'select2/i18n/fr',
  [],
  require('select2/src/js/select2/i18n/fr')
)

let checkoutConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'checkout-content',

  /* Mixins
   * @type {Object}
   */
  mixins: {
    events: events,
    selectConfig: { disabled: true }
  },

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED CHECKOUT')

    this.setupCheckout()
    this.loyaltyEventHandler()
    this.attachGlobalEventHandlers()
    this.getInfoAstenAsync()
    this.buildCustomerCheckoutStep()
    this.addressStepEventHandler()
    this.checkIsPayementStep()
  },

  /**
   * Get informations from Asten API asynchronously
   * @void
   */
  getInfoAstenAsync () {
    if ($('#go').val() == 'go') {
      $.ajax({
        type: 'GET',
        url: window.location.href,
        cache: false,
        async: true,
        data: {
          get_info_asten: 'get_info_asten',
          ajax: 1
        }
      }).done(function (resultJson) {
        if (resultJson.string_html != 'undefined') {
          $('.cart-summary-loyalty').html('' + resultJson.string_html)
        }
        if (resultJson.loyalty_points_total != 'undefined') {
          $('.loyalty_points_total').html('' + resultJson.loyalty_points_total)
        }
      })
    }
  },

  /**
   * Cart page event handler
   * @void
   */
  attachGlobalEventHandlers () {
    const CHECKOUT_CONTENT = this.data().component
    const LEFT_CONTAINER = CHECKOUT_CONTENT.find('.checkout-left-column')
    const CHECKOUT_STEP = CHECKOUT_CONTENT.find('.js-checkout-step')
    const BODY = $('body')
    const DELIVERY = $('#js-delivery')
    let _selectedDelivery = DELIVERY.find('[name^="delivery_option"]:checked')
    let idCustomer = $("input[name='id_customer']").val()
    let _deliveryForm = $('#delivery-address')
    let _invoiceForm = $('#invoice-address')
    let _customerAddressForm = $('#customer-address-form')

    if (idCustomer) {
      $('.form-numero_carte_fidelite').hide()
      $('.form-numero_carte_fidelite').removeClass('d-flex')
      $('.loyalty-fetch-code').hide()
    }

    prestashop.on(
      'updatedDeliveryForm',
      params => {
        if (typeof params.deliveryOption === 'undefined' || params.deliveryOption.length === 0) {
          return
        }
        // Hide all carrier extra content ...
        $('.carrier-extra-content').hide()
        // and show the one related to the selected carrier
        $('.delivery-option').removeClass('active')
        params.deliveryOption.addClass('active')
      }
    )

    prestashop.on(
      'updateCart',
      () => {
        this.getInfoAstenAsync()
      }
    )

    BODY.on(
      'change',
      '.js-input-delivery:checked',
      event => {
        let _delivery = $('#delivery-addresses')
        let _invoice = $('#invoice-addresses')
        let _currentAdress = $(event.currentTarget)
        let _adressParent = _currentAdress.parents('.js-label-delivery')

        if (_adressParent.hasClass('id-address-delivery')) {
          _delivery.find('.js-label-delivery.selected').removeClass('selected')
        } else {
          _invoice.find('.js-label-delivery.selected').removeClass('selected')
        }

        _adressParent.not('.selected')
          .addClass('selected')
      }
    )

    BODY.on(
      'change',
      '[name="payment-option"]:checked',
      event => {
        let _target = $(event.currentTarget)
        let _label = _target.parents('label')
        let _parent = $('.payment-options')

        _parent.find('label').removeClass('selected')

        _label.not('.selected')
          .addClass('selected')
      }
    )

    BODY.on(
      'click',
      '.js-checkout-step .step-title',
      event => {
        let _el = $(event.currentTarget)
        let _collapse = _el.next('.collapse')
        let _isAllowed = _el.hasClass('not-allowed')

        if (!_isAllowed) {
          _collapse.not('.show').collapse({ parent: LEFT_CONTAINER })
          _collapse.not('.show').collapse('show')
        }

        this.checkIsPayementStep(_el)
      }
    )

    BODY.on(
      'click',
      '.js-gift-checkbox',
      () => {
        $('#gift').collapse('toggle')
      }
    )

    // checkout step on load
    CHECKOUT_STEP.each(
      (_index, _el) => {
        let _collapse = $(_el).find('.step-content.collapse')
        let _isCurrent = $(_el).hasClass('-current')

        if (_isCurrent) {
          _collapse.not('.show').collapse('show')
        }
      }
    )

    $('.page-order-confirmation .step-edit').on(
      'click',
      event => {
        let _el = $(event.currentTarget)
        let _target = _el.data('target')
        let _collapse = $(`#${_target}`).find('.collapse')
        let _isAllowed = $(`#${_target}`).hasClass('not-allowed')

        if (!_isAllowed) {
          _collapse.not('.show').collapse({ parent: LEFT_CONTAINER })
          _collapse.not('.show').collapse('show')
          $('.page-order-confirmation').removeClass('-current')
        }

        this.checkIsPayementStep(_el)
      }
    )

    if (_selectedDelivery.length > 0) {
      this.shippingStepEventHandler(_selectedDelivery)
    }

    DELIVERY.on(
      'change',
      '[name^="delivery_option"]:checked',
      event => {
        let _target = $(event.currentTarget)

        this.shippingStepEventHandler(_target)
      }
    )

    // loyalty
    $("input[name='id_carte_fidelite']").on(
      'click',
      event => {
        let _input = $(event.currentTarget)

        if (_input.val() == 1 /* oui */) {
          $('.have-loyalty-card').show()
          $('.profitez-avantage').hide()
        } else if (_input.val() == 2/* non */) {
          $('.have-loyalty-card').hide()
          $('.profitez-avantage').show()
        }
      }
    )

    let _code = $("input[name='numero_carte_fidelite']").val()

    if (_code) {
      $('#code').val(_code)
      $('#oui').prop('checked', true)
      $('#oui').click()
    }

    $('#address-form').submit(function () {
      // event.preventDefault() // this will prevent the default submit
      if ($("input[name='address1']").attr('readonly')) {
        $('#notifications-adresses ul').html('' +
          '<li class="alert alert-danger">Vous devez valider l\'autocomplete champ code postal ville pour pouvoir remplir le champ adresse</li>' +
          '<li class="alert alert-danger">Champ adresse requis</li>'
        )
        $('#notifications-adresses').show()

        return false
      }
      /* $(this).unbind('submit')
        .submit() */ // continue the submit unbind preventDefault
    })

    $('.link-toggler').on(
      'click',
      event => {
        event.preventDefault()

        $('.num_code').toggleClass('show')
      }
    )

    /* $("input[name='firstname']").on(
      'input',
      () => {
        alert($("input[name='birthday']").val())
      }
    )

    /* if ($("input[name='address1']").prop('defaultValue') ||
      $("input[name='address1']").val()) {
      $("input[name='address1']").removeAttr('readonly')
    } else {
      $("input[name='address1']").attr('readonly', 'readonly')
    } */

    $('#notifications-adresses').hide()

    if (_deliveryForm.length > 0) {
      this.createSelectPostcode(_deliveryForm)
      this.createSelectAddress(_deliveryForm)
    }

    if (_invoiceForm.length > 0) {
      this.createSelectPostcode(_invoiceForm)
      this.createSelectAddress(_invoiceForm)
    }

    if (_customerAddressForm.length > 0) {
      this.createSelectPostcode(_customerAddressForm)
      this.createSelectAddress(_customerAddressForm)
    }
  },

  /**
   * Handle address step event
   */
  addressStepEventHandler () {
    const CHECKOUT_CONTENT = this.data().component
    const DELYVERY_WRAPPER = CHECKOUT_CONTENT.find('.customer-address--wrapper')
    const INVOICE_WRAPPER = CHECKOUT_CONTENT.find('.invoice-address--wrapper')
    const DELIVERY_FORM = DELYVERY_WRAPPER.find('#delivery-address')
    // const INVOICE_FORM = INVOICE_WRAPPER.find('#invoice-address')
    const USE_SAME_ADDRESS = DELIVERY_FORM.find('#use_same_address')
    const INVOICE_ADDRESS_TEXT = INVOICE_WRAPPER.find('.invoice-address-text')

    if (USE_SAME_ADDRESS.length > 0) {
      let _text = this.toggleInvoiceText(USE_SAME_ADDRESS)

      INVOICE_ADDRESS_TEXT.find('.text').html(_text)
      USE_SAME_ADDRESS.on(
        'click',
        event => {
          let _target = $(event.currentTarget)

          _text = this.toggleInvoiceText(_target)
          INVOICE_ADDRESS_TEXT.find('.text').html(_text)
        }
      )
    }
  },

  toggleInvoiceText (_input) {
    let _text = _input.is(':checked') ? 'Mon adresse de facturation est identique à mon adresse de livraison' : 'Je valide mon adresse de livraison pour ensuite saisir mon adresse de facturation'

    return _text
  },

  shippingStepEventHandler (_target) {
    let _mondialrelay = $('.mondialrelay--wrapper')
    let _mondialrelayWrapper = $('.mondialrelay-summary--wrapper')
    let _colissimo = $('.collisimo-summary--wrapper')
    let _deliveryWrapper = $('.delivery-option-setting--wrapper')
    let _deliveryInfo = $('#delivery-information-delivery')

    switch (_target.data('name')) {
      case 'Mondial Relay': {
        _colissimo.hide()
        _deliveryInfo.hide()
        _mondialrelay.slideDown()
        $('#delivery_information').val('')

        this.mixins.events.smoothScroll('.mondialrelay--wrapper')

        if (_mondialrelayWrapper.length === 0 ||
                    _mondialrelayWrapper.css('display') === 'none' ||
                    _mondialrelayWrapper.css('display') === 'undefined') {
          _deliveryWrapper.hide()
        } else {
          $("button[name='confirmDeliveryOption']").attr('disabled', false)
          _deliveryWrapper.show()
        }
        break
      }
      case 'Chronopost':
      case 'Chronopost Corse':
      case 'Chronopost Zone difficile':
      case 'Coliéco':
      case 'Colissimo': {
        _deliveryWrapper.show()
        _colissimo.show()
        _deliveryInfo.show()
        _mondialrelayWrapper.hide()

        $("button[name='confirmDeliveryOption']").attr('disabled', false)
        $('#livraison-name').text(_target.data('name'))

        break
      }
      default: {
        _colissimo.hide()
        _deliveryWrapper.hide()
        $("button[name='confirmDeliveryOption']").attr('disabled', false)
        break
      }
    }
  },

  /**
   * payement step setup
   * @void
   */
  setupCheckout () {
    const CHECKOUT_CONTENT = this.data().component
    let _modalTerm = CHECKOUT_CONTENT.find('#modal-terms')
    let _terms = CHECKOUT_CONTENT.find('.js-terms a')

    _terms.on(
      'click',
      event => {
        event.preventDefault()

        let _url = $(event.target).attr('href')

        if (_url) {
          // TODO: Handle request if no pretty URL
          _url += `?content_only=1`

          $.get(
            _url,
            content => {
              let _gpdrPage = $(content).find('.page-content--cms')

              _modalTerm.find('.js-modal-content')
                .html(
                  _gpdrPage.contents()
                )
            }
          )
            .fail(
              resp => {
                prestashop.emit(
                  'handleError',
                  {
                    eventType: 'clickTerms',
                    resp: resp
                  }
                )
              }
            )
        }

        _modalTerm.modal('show')
      }
    )
  },

  resetFormSubscription () {
    $("input[name='firstname']").val('')
    $("input[name='lastname']").val('')
    $("input[name='email']").val('')
    $("input[name='id_gender']").removeAttr('checked')
    $("input[name='birthday']").val('')
    $("input[name='newsletter']").removeAttr('checked')
  },

  /**
   * Build collapse on customer step in checkout page
   * @void
   */
  buildCustomerCheckoutStep () {
    const CUSTOMER_TAB = $('#tab-personal-information')
    const GUEST_FORM = $('body').find('.registration-form-checkout')

    if (GUEST_FORM) {
      const BUTTONS = GUEST_FORM.find('.tab-guest-item button')
      const COLLAPSE = GUEST_FORM.find('.customer-form--collapse')
      let _tabLoyalty = GUEST_FORM.find('.toggle-loyalty')
      let _alertLink = GUEST_FORM.find('#loyalty_card_number_nofound a')
      let _submit = $('#save-customer-guest-order')
      let _loyaltyForm = GUEST_FORM.find('.form-numero_carte_fidelite input')

      BUTTONS.on(
        'click',
        event => {
          let _target = $(event.currentTarget)
          let _action = _target.data('action')

          COLLAPSE.addClass(`-current-${_action}`)

          switch (_action) {
            case 'toggle-loyalty' : {
              this.toggleOrderCollapse(_tabLoyalty, COLLAPSE, 2, 3)

              _submit.attr('disabled', true)

              if (COLLAPSE.hasClass('-current-toggle-mail')) {
                COLLAPSE.removeClass('-current-toggle-mail')
                COLLAPSE.not('show').collapse('show')
                $('[data-action="toggle-mail"]').removeClass('expanded')
                $('[data-action="toggle-loyalty"]').addClass('expanded')
              } else {
                COLLAPSE.collapse('toggle')
              }

              break
            }
            case 'toggle-mail' : {
              this.toggleOrderCollapse(_tabLoyalty, COLLAPSE, 3, 2)

              _submit.removeAttr('disabled')
              _loyaltyForm.val('')

              if (COLLAPSE.hasClass('-current-toggle-loyalty')) {
                COLLAPSE.removeClass('-current-toggle-loyalty')
                COLLAPSE.not('show').collapse('show')
                $('[data-action="toggle-loyalty"]').removeClass('expanded')
                $('[data-action="toggle-mail"]').addClass('expanded')
              } else {
                COLLAPSE.collapse('toggle')
              }

              break
            }
          }

          COLLAPSE.on(
            'shown.bs.collapse',
            () => {
              if (COLLAPSE.hasClass('-current-toggle-mail')) {
                $('[data-action="toggle-mail"]').addClass('expanded')
              } else {
                $('[data-action="toggle-loyalty"]').addClass('expanded')
              }
            }
          )

          COLLAPSE.on(
            'hidden.bs.collapse',
            () => {
              if (COLLAPSE.hasClass('-current-toggle-mail')) {
                $('[data-action="toggle-mail"]').removeClass('expanded')
              } else {
                $('[data-action="toggle-loyalty"]').removeClass('expanded')
              }
            }
          )
        }
      )

      if (_alertLink) {
        _alertLink.on(
          'click',
          event => {
            event.preventDefault()
            let _target = $(event.currentTarget)
            let _action = _target.data('action')

            switch (_action) {
              case 'login-form' : {
                let _loginTabLink = CUSTOMER_TAB.find('a[href="#checkout-login-form"]')

                _loginTabLink.tab('show')
                break
              }
              case 'toggle-mail' : {
                COLLAPSE.addClass('-current-toggle-mail')

                this.toggleOrderCollapse(_tabLoyalty, COLLAPSE, 3, 2)

                _submit.removeAttr('disabled')
                _loyaltyForm.val('')

                if (COLLAPSE.hasClass('-current-toggle-loyalty')) {
                  COLLAPSE.removeClass('-current-toggle-loyalty')
                  COLLAPSE.not('show').collapse('show')
                  $('[data-action="toggle-loyalty"]').removeClass('expanded')
                  $('[data-action="toggle-mail"]').addClass('expanded')
                } else {
                  COLLAPSE.collapse('toggle')
                }
                break
              }
            }
          }
        )
      }
    }
  },

  toggleOrderCollapse (_first, _last, _orderFirst, _orderLast) {
    _first.attr('data-order', _orderFirst)
    _last.attr('data-order', _orderLast)
  },

  checkIsPayementStep (_trigger) {
    const CHECKOUT_CONTENT = this.data().component
    const LEFT_CONTAINER = CHECKOUT_CONTENT.find('.checkout-left-column')
    const RIGHT_CONTAINER = CHECKOUT_CONTENT.find('.checkout-right-column')
    const CHECKOUT_STEP = CHECKOUT_CONTENT.find('.js-checkout-step')
    let _isCurrent = CHECKOUT_STEP.hasClass('-current')
    let _target = !_trigger ? CHECKOUT_STEP : _trigger
    let _isPayement = _target.is('#checkout-payment-step')

    if (_isCurrent && _isPayement) {
      LEFT_CONTAINER.addClass('col-lg-8')
      RIGHT_CONTAINER.addClass('col-lg-4 d-block')
    } else {
      LEFT_CONTAINER.removeClass('col-lg-8')
      RIGHT_CONTAINER.removeClass('col-lg-4 d-block')
    }
  },

  /**
   * check if date format is DDMMYYYY
   * @param  {String}  str date to test
   * @return {Boolean}
   */
  isDDMMYYYY (str) {
    let dateformat = /^((0[1-9]|[12][0-9]|3[01])(\/)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\/)(02))|((0[1-9]|[12][0-9]|3[0])(\/)(0[469]|11))(\/)\d{4}$/

    return dateformat.test(str)
  },

  formatDate (dateString) {
    let allDate = dateString.split(' ')
    let thisDate = allDate[0].split('-')
    let newDate = [thisDate[2], thisDate[1], thisDate[0] ].join('/')

    return newDate
  },

  /**
   * Loyalty  event handler
   */
  loyaltyEventHandler () {
    let self = this
    let ajaxRequest
    const CUSTOMER_FORM = $('#customer-form')

    $.ajaxSetup({
      beforeSend: function (xhr, settings) {
        if (typeof settings.url !== undefined && settings.url !== '' && settings.url.includes('http')) {
          let params = (new URL(settings.url)).searchParams
          let action = params.get('action')

          if (action == 'addressForm') {
            return false
          }
        }
      }
    })

    $(document).on(
      'click',
      '#subscribe-fid',
      event => {
        // event.preventDefault()
        $('#subscribe-fid').attr('disabled', 'disabled')
        console.log('test fid clicked')
        console.log($('#url_asten').val())

        ajaxRequest = $.ajax(
          {
            type: 'GET',
            url: $('#url_asten').val(),
            cache: false,
            data: {
              method: 'create_card',
              ajax: true
            },
            success: function (resultJson) {
              let result = JSON.parse(resultJson)

              if (result.status == 'success') {
                let numFid = result.results.loyalty_card_number

                $.ajax(
                  {
                    type: 'POST',
                    url: $('#url_asten').val(),
                    cache: false,
                    data: {
                      method: 'customer_create',
                      modif: 'yes',
                      loyalty_card_number: numFid,
                      ajax: true
                    },
                    success: resultJson => {
                      let result = JSON.parse(resultJson)

                      if (result.status == 'success') {
                        $('#subscribe-fid').after('Votre inscription au programme de fidélité a été réalisée avec succès.<br />Votre numéro de client est le suivant <strong>' + numFid + '</strong>')
                        $('#subscribe-fid').remove()

                        return true
                      } else {
                        return false
                      }
                    }
                  }
                )

                return true
              } else {
                // error
                $('#error_loyalty_card_number ul li').text("Erreur lors de l'appel de l'API, Veuillez réessayer svp")
                $('#error_loyalty_card_number').show()
                $('#subscribe-asten').removeAttr('disabled')

                return false
              }
            }
          }
        )

        return false
      }
    )

    $('#save-carte_number').on(
      'click',
      event => {
        event.preventDefault()
        let code = $('#code').val()

        $('#empty_loyalty_card_number').hide()
        $('#nofound_loyalty_card_number').hide()
        $('#error_loyalty_card_number').hide()
        if (code) {
          $.ajax(
            {
              type: 'GET',
              url: $('#url_asten').val(),
              cache: false,
              data: {
                method: 'fetch_this_code',
                code: code,
                modif: 'yes',
                ajax: true
              },
              success: resultJson => {
                let result = JSON.parse(resultJson)

                if (result.status == 'success') {
                  location.reload()
                }

                if (result.status == 'error') {
                  $('#error_loyalty_card_number').show()

                  return false
                }

                if (result.status == 'not_found') {
                  $('#nofound_loyalty_card_number').show()

                  return false
                }
              }
            }
          )

          return false
        } else {
          $('#empty_loyalty_card_number').show()
        }
      }
    )

    $('#subscribe-asten').on(
      'click',
      event => {
        event.preventDefault()
        $('#subscribe-asten').attr('disabled', 'disabled')
        let firstname = $("input[name='firstname']").val()
        let lastname = $("input[name='lastname']").val()
        let email = $("input[name='email']").val()

        let datenaissance = $("input[name='birthday']").val()

        $('#empty_loyalty_card_number').hide()
        $('#nofound_loyalty_card_number').hide()
        $('#error_loyalty_card_number').hide()
        if (typeof ajaxRequest !== 'undefined') {
          ajaxRequest.abort()
        }
        if (firstname && lastname && email) {
          ajaxRequest = $.ajax(
            {
              type: 'GET',
              url: $('#url_asten').val(),
              cache: false,
              data: {
                method: 'create_card',
                ajax: true
              },
              success: function (resultJson) {
                let result = JSON.parse(resultJson)

                if (result.status == 'success') {
                  // save this data to loyalty_card_number_account
                  $("input[name='numero_carte_fidelite']").val(result.results.loyalty_card_number)

                  $.ajax(
                    {
                      type: 'POST',
                      url: $('#url_asten').val(),
                      cache: false,
                      data: {
                        method: 'customer_create',
                        email: email,
                        first_name: firstname,
                        last_name: lastname,
                        birth_date: datenaissance,
                        modif: 'yes',
                        loyalty_card_number: $("input[name='numero_carte_fidelite']").val(),
                        ajax: true
                      },
                      success: resultJson => {
                        let result = JSON.parse(resultJson)

                        if (result.status == 'success') {
                          // refresh this page
                          location.reload()

                          return true
                        } else {
                          // error
                          $('#error_loyalty_card_number ul li').text(result.error_msg)
                          $('#error_loyalty_card_number').show()
                          $('#subscribe-asten').removeAttr('disabled')

                          return false
                        }
                      }
                    }
                  )

                  return true
                } else {
                  // error
                  $('#error_loyalty_card_number ul li').text("Erreur lors de l'appel de l'API, Veuillez réessayer svp")
                  $('#error_loyalty_card_number').show()
                  $('#subscribe-asten').removeAttr('disabled')

                  return false
                }
              }
            }
          )
        }

        return false
      }
    )

    $("input[name='numero_carte_fidelite']").on(
      'input',
      function (event) {
        event.preventDefault()
        let code = $(this).val()
        let _section = CUSTOMER_FORM.find('.form-container')
        let _genderForm = $("input[name='id_gender']:checked")
        let _firstnameForm = $("input[name='firstname']")
        let _lastnameForm = $("input[name='lastname']")
        let _emailForm = $("input[name='email']")
        let _birthdayForm = $("input[name='birthday']")
        let _comment = $(this).next('.form-control-comment')

        if (code) {
          _comment.hide()
        } else {
          _comment.show()
        }

        $('#save-customer').attr('disabled', 'disabled')
        $('#save-customer-guest-order').attr('disabled', 'disabled')
        if (code.length === 13) {
          // run ajax here for fetch info user by number card fidelity
          $('#loyalty_card_number_nofound').hide()
          _section.addClass('is--loading')

          if (typeof ajaxRequest !== 'undefined') {
            ajaxRequest.abort()
          }

          ajaxRequest = $.ajax(
            {
              type: 'GET',
              url: $('#url_asten').val(),
              cache: false,
              async: true,
              data: {
                code: code,
                is_exist_user: 1,
                method: 'fetch_this_code',
                ajax: true
              },
              success: function (resultJson) {
                _section.removeClass('is--loading')
                // self.resetFormSubscription()

                if (resultJson) {
                  let result = JSON.parse(resultJson)

                  if (result.status == 'success') {
                    console.log('info user', result)
                    let info = result.results
                    let isMSIE = result.isMSIE

                    if (result.user_id && result.msg_error) {
                      $('#loyalty_card_number_nofound').find('.alert span')
                        .html(result.msg_error)
                      $('#loyalty_card_number_nofound').show()

                      return true
                    }

                    if (!_firstnameForm.val()) {
                      _firstnameForm.val(info.first_name)
                    }

                    if (!_lastnameForm.val()) {
                      _lastnameForm.val(info.last_name)
                    }

                    if (!_emailForm.val()) {
                      _emailForm.val(info.email)
                    }

                    if (!_genderForm.val()) {
                      if (['Mlle', 'Mlle.', 'Mme', 'Mme.'].includes(info.title.value)) {
                        $("input[name='id_gender'][value='2']").click()
                      } else if (['M', 'M.'].includes(info.title.value)) {
                        $("input[name='id_gender'][value='1']").click()
                      }
                    }

                    if (info.birth_date && !_birthdayForm.val()) {
                      if (!isMSIE) {
                        _birthdayForm.val(info.birth_date)
                      } else {
                        _birthdayForm.val(self.formatDate(info.birth_date))
                      }
                    }

                    if (info.newsletter_date_on) {
                      $("input[name='newsletter']").attr('checked', 'checked')
                    }

                    // activate button here
                    $('#save-customer').removeAttr('disabled')
                    $('#save-customer-guest-order').removeAttr('disabled')
                    $('#loyalty_card_number_nofound.custom').hide()
                  } else if (result.status == 'error') {
                    // display error
                    $('#loyalty_card_number_nofound.custom').html('Erreur lors de l\'appel de l\'API, Veuillez réessayer svp')
                    $('#loyalty_card_number_nofound.custom').show()
                    $('#loyalty_card_number_nofound').show()
                  } else if (result.status == 'not_found') {
                    // display error
                    $('#loyalty_card_number_nofound.custom').hide()
                    $('#loyalty_card_number_nofound').show()
                  }
                } else {
                  $('#loyalty_card_number_nofound.custom').html('Erreur lors de l\'appel de l\'API, Veuillez réessayer svp')
                  $('#loyalty_card_number_nofound.custom').show()
                  $('#loyalty_card_number_nofound').show()
                }
              }
            }
          )
        } else {
          // display error
          $('#loyalty_card_number_nofound').show()
        }
      }
    )
  },

  createSelectPostcode (_wrapper) {
    let _selectForm = _wrapper.find("select[name='postcode_city']")

    // Autocomplete for ville
    if (_selectForm.length > 0) {
      let _dropDownParent = _selectForm.parent('.custom-select2')
        .find('.result-search--wrapper')

      // select2 postcode_city
      _selectForm.on(
        'select2:select',
        () => {
          this.createSelectAddress(_wrapper)
        }
      )

      _selectForm.select2(
        {
          dropdownParent: _dropDownParent,
          minimumInputLength: 3,
          language: 'fr',
          ajax: {
            type: 'POST',
            url: $('#url_asten').val(),
            cache: false,
            async: true,
            data: params => {
              let _ville = params.term
              let _country = _wrapper.find("select[name='id_country']").children('option:selected')

              return {
                Valeur: _ville,
                Pays: _country.val(),
                Cnt: 20,
                method: 'auto_complete_postal',
                ajax: true
              }
            },
            processResults: data => {
              let _resultJson = JSON.parse(data)

              let _result = $.map(
                _resultJson.lists.villes,
                item => {
                  return {
                    id: `${item.cp} ${item.ville}`,
                    text: item.libelle,
                    cp: item.cp,
                    ville: item.ville
                  }
                }
              )

              return { results: _result }
            }
          },
          templateResult: item => {
            let _link = $(`<a>`)

            _link.attr('data-cp', item.cp)
              .attr('data-ville', item.ville)
              .append(item.text)

            return _link
          },
          templateSelection: state => {
            let _target = $(state.element)
            let _targetParent = _target.parents('.form-fields')
            let _cp = state.cp
            let _ville = state.ville

            console.log('STATE POST', state)

            this.mixins.selectConfig.disabled = false

            if (!state.selected) {
              _targetParent.find("select[name='address1']")
                .val(null)
                .trigger('change')
              _targetParent.find("input[name='id_voie']").val('')
              _targetParent.find("input[name='voie_choice']").val('')
            }

            if (typeof _cp !== 'undefined') {
              _targetParent.find("input[name='postcode']").val(_cp)
            }

            if (typeof _ville !== 'undefined') {
              _targetParent.find("input[name='city']").val(_ville)
            }

            return state.text
          }
        }
      )
    }
  },

  createSelectAddress (_wrapper) {
    let _isDisabled = this.mixins.selectConfig.disabled
    let _selectAdress = _wrapper.find("select[name='address1']")
    let _dropDownParent = _selectAdress.parent('.custom-select2')
      .find('.result-search--wrapper')

    if (_selectAdress.length > 0) {
      _selectAdress.select2(
        {
          dropdownParent: _dropDownParent,
          disabled: _isDisabled,
          minimumInputLength: 3,
          language: 'fr',
          tags: true,
          ajax: {
            type: 'POST',
            url: $('#url_asten').val(),
            cache: false,
            async: true,
            data: params => {
              let _address = params.term
              let _ville = _wrapper.find("input[name='postcode']").val()
              let _country = _wrapper.find("select[name='id_country']").children('option:selected')

              return {
                Valeur: _address,
                Pays: _country.val(),
                Cnt: 20,
                CpVille: _ville,
                AvecCAdrs: 1,
                method: 'auto_complete_adress',
                ajax: true
              }
            },
            processResults: data => {
              let _resultJson = JSON.parse(data)

              let _result = $.map(
                _resultJson.lists.addresses,
                item => {
                  return {
                    id: item.voie,
                    text: item.libelle,
                    id_voie: item.id_voie,
                    post_code: item.post_code,
                    post_code_ville: item.post_code_ville
                  }
                }
              )

              return { results: _result }
            }
          },
          templateResult: item => {
            let _link = $(`<a>`)

            _link.attr('data-voie', item.id)
              .attr('data-id_voie', item.id_voie)
              .attr('data-voie_choice', item.text)
              .attr('data-post_code', item.post_code)
              .attr('data-post_code_ville', item.post_code_ville)
              .append(item.text)

            return _link
          },
          templateSelection: state => {
            let _target = $(state.element)
            let _targetParent = _target.parents('.form-fields')
            let _idVoie = state.id_voie
            let _voieChoice = state.text
            let _postalCode = state.post_code
            let _postCodeVille = state.post_code_ville

            console.log('STATE', state)

            if (typeof _idVoie !== 'undefined') {
              _targetParent.find("input[name='id_voie']").val(_idVoie)
            }

            if (typeof _voieChoice !== 'undefined') {
              _targetParent.find("input[name='voie_choice']").val(_voieChoice)
            }

            if (typeof _postalCode !== 'undefined') {
              if (_targetParent.find("input[name='postcode']").val() !== _postalCode) {
                let _selectForm = _targetParent.find("select[name='postcode_city']")
                let _option = new Option(_postCodeVille, _postCodeVille, true, true)

                _selectForm.append(_option).trigger('change')
                _targetParent.find("input[name='postcode']").val(_postalCode)
              }
            }

            return state.text
          }
        }
      )
    }
  }
}

/**
 * Module export
 */
export default checkoutConfiguration
