import { EVENTS } from '~/config/constant'
import events from '~/utils/events'
import prestashop from 'prestashop'
import 'jquery/ui/jquery.ui.datepicker.min.js'

let commonConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'henaff-common',

  /**
   * Mixins
   * @type {Object}
   */
  mixins: events,

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @vois
   */
  created () {
    console.log('CREATED COMMON')

    this.updateLazyLoad()
    this.createCustomSelect()
    this.createCustomFile()
    this.setScrollWidth()
    this.attachGlobalEventHandlers()
    this.prepareDatalayer()
  },

  /**
   * Cart page event handler
   * @void
   */
  attachGlobalEventHandlers: function () {
    const BODY = this.data().component
    const ANCHOR_LINK = BODY.find('a[href*="#"]')

    // Shopping cart updated
    prestashop.on(
      'updatedCart',
      () => {
        this.updateLazyLoad()
      }
    )

    // Shopping product updated
    prestashop.on(
      'updatedProduct',
      () => {
        this.createCustomSelect()
      }
    )

    // DOM Reload event
    BODY.on(
      EVENTS.DOM_RELOAD,
      () => {
        this.updateLazyLoad()
        this.createCustomSelect()
        this.createCustomFile()
      }
    )

    /**
     * Scan for .lazyload
     * Scroll spy
     */
    $(window).on(
      'load scroll resize',
      () => {
        this.updateLazyLoad()
      }
    )

    // Smooth scroll event
    ANCHOR_LINK
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .not('[href*="#/"]')
      .on(
        'click',
        event => {
          event.preventDefault()

          let _hash = event.currentTarget.hash

          this.mixins.smoothScroll(_hash)
        }
      )

    // Input type tel set restriction
    BODY
      .find('input[type="tel"]')
      .each(
        (_index, _el) => {
          this.setInputFilter(
            _el,
            value => {
              // Allow digits only,+,(,) and [ .-], using a RegExp
              return /^\+?\(?\d{0,3}\)?[\s.-]?\d*[\s.-]?\d*[\s.-]?\d*$/.test(value)
            }
          )
        }
      )
    // product card image
    $('article.product-card, .list-product-pack')
      .each(
        (index, card) => {
          let image = $(card).find('.card-image')

          if (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0)) {
            image.on(
              'touchstart',
              event => {
                if (image.hasClass('hover')) {
                  return true
                } else {
                  image.addClass('hover')
                  $('.card-image').not(image)
                    .removeClass('hover')

                  event.preventDefault()

                  return false
                }
              }
            )
          }
        }
      )
  },

  /**
   * Update lazy load
   * @void
   */
  updateLazyLoad () {
    const BODY = this.data().component
    const LAZY = BODY.find('.lazyload')

    if (LAZY.length > 0) {
      LAZY.each(
        (_i, _el) => {
          /**
           * Check if inview
           * Will trigger inview event
           */
          if (this.mixins.isInViewport(_el)) {
            $(_el).trigger('inview', [_el])
          }
        }
      )
    }

    /**
   * Set inview handler
   * Will start image load
   */
    $('body').on(
      'inview',
      (event, eventTrigger) => {
        const ELEMENT = $(eventTrigger)
        const IMAGE = ELEMENT.find('img')

        if (IMAGE.length > 0) {
          const SOURCE = IMAGE.data('src')
          const SRCSET = IMAGE.data('srcset')
          const SIZES = IMAGE.data('sizes')

          if (IMAGE.get(0).complete) {
            IMAGE.trigger('load')
          }

          IMAGE.on(
            'load',
            () => {
              ELEMENT.addClass('ready')
            }
          )

          if (SOURCE) {
            IMAGE.removeAttr('data-src')
            IMAGE.attr('src', SOURCE)
          }

          if (SRCSET) {
            IMAGE.removeAttr('data-srcset')
            IMAGE.attr('srcset', SRCSET)
          }

          if (SIZES) {
            IMAGE.removeAttr('data-sizes')
            IMAGE.attr('sizes', SIZES)
          }
        }

        /**
         * Unset lazyload classname
         */
        ELEMENT.removeClass('lazyload')
      }
    )
  },

  /**
   * Create a custom selectbox
   */
  createCustomSelect () {
    const BODY = this.data().component
    const SELECT = BODY.find('.custom-select select')

    SELECT.each(
      (_index, _el) => {
        $(_el).after('<span class="holder"></span>')
        $(_el).next('.holder')
          .text(
            $(_el).find(':selected')
              .text()
          )

        $(_el).on(
          'change',
          event => {
            let _selectedOption = $(event.currentTarget).find(':selected')

            $(event.currentTarget).next('.holder')
              .text(_selectedOption.text())
          }
        )
      }
    )
  },

  /**
   * Create custom input file
   */
  createCustomFile () {
    const BODY = this.data().component
    const FILE_INPUT = BODY.find('.custom-file input')

    FILE_INPUT.each(
      (_index, _el) => {
        const BUTTON_TEXT = $(_el).data('browse')

        $(_el).after('<label for="fileUpload"></span>')

        const LABEL = $(_el).next('label')

        LABEL.append(`<span class="text"></span><span class="btn">${BUTTON_TEXT}</span>`)

        $(_el).on(
          'change',
          () => {
            let _fileName = ''

            if (_el.files && _el.files.length > 0) {
              _fileName = _el.files[0].name
            }

            if (_fileName) {
              LABEL.find('.text').html(_fileName)
            }
          }
        )
      }
    )
  },

  /**
   * set scrollbar width as property
   */
  setScrollWidth () {
    // document.scrollingElement not support IE11 use pollyfif https://www.npmjs.com/package/scrolling-element
    const DOCUMENT = document.documentElement
    let _scrollWidth = window.innerWidth - DOCUMENT.clientWidth

    DOCUMENT.style
      .setProperty(
        '--scrollbar-width',
        `${_scrollWidth}px`
      )
  },

  /**
   * Restricts input for the set of matched elements to the given inputFilter function.
   */
  setInputFilter (textbox, inputFilter) {
    ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(
      function (event) {
        textbox.addEventListener(
          event,
          function () {
            if (inputFilter(this.value)) {
              this.oldValue = this.value
              this.oldSelectionStart = this.selectionStart
              this.oldSelectionEnd = this.selectionEnd
            } else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
              this.value = this.oldValue
              this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd)
            } else {
              this.value = ''
            }
          }
        )
      }
    )
  },

  /**
   * Prepare datalayer for gtag products lists
   */
  prepareDatalayer () {
    let dataList = []
    let counter = 1

    if (!window.dataLayer) {
      window.dataLayer = []
    }

    $('article.product-card, .list-product-pack').each(function () {
      $(this).attr('data-product-position', counter)
      let tmp = {
        product_id: $(this).data('id-product'),
        product_name: $(this).data('product-name'),
        product_category: $(this).data('product-category'),
        product_price_ati: $(this).data('product-price-ati'),
        product_price_tf: $(this).data('product-price-tf'),
        product_position: counter
      }

      if ($(this).data('product-brand')) {
        tmp.product_brand = $(this).data('product-brand')
      }
      dataList.push(tmp)

      counter++
    })

    if ($('article.product-card, .list-product-pack').length) {
      console.log('product-displayed', dataList)
      window.dataLayer.push({
        event: 'product-displayed',
        product: dataList
      })
    }

    let waitForEl = function (selector, callback) {
      if ($(selector).length) {
        callback()
      } else {
        setTimeout(function () {
          waitForEl(selector, callback)
        }, 100)
      }
    }

    let selector = 'article.product-card a, .list-product-pack a'

    waitForEl(selector, function () {
      $(document).on('click touchend', selector, function () {
        $(this).parents()
          .filter(function () {
            if ($(this).data('id-product')) {
              let data = {
                'event': 'product-clicked',
                'product': []
              }
              let tmp = {
                'product_id': $(this).data('id-product'),
                'product_name': $(this).data('product-name'),
                'product_category': $(this).data('product-category'),
                'product_price_ati': $(this).data('product-price-ati'),
                'product_price_tf': $(this).data('product-price-tf'),
                'product_position': $(this).data('product-position')
              }

              if ($(this).data('product-brand')) {
                tmp.product_brand = $(this).data('product-brand')
              }
              data.product.push(tmp)

              console.log('product-clicked', data.product[0])
              window.dataLayer.push(data)
            }
          })
          .eq(0)
      })
    })

    let selectorAddToCart = 'button.add-to-cart'

    waitForEl(
      selectorAddToCart,
      function () {
        $(document).on(
          'click touchend',
          selectorAddToCart,
          function () {
            let data = {}

            if ($(this).data('id-product')) {
              data = {
                'event': 'add-to-cart',
                'product': [{
                  'product_id': $(this).data('id-product'),
                  'product_name': $(this).data('product-name'),
                  'product_category': $(this).data('product-category'),
                  'product_brand': $(this).data('product-brand'),
                  'product_price_ati': $(this).data('product-price-ati'),
                  'product_price_tf': $(this).data('product-price-tf')
                }]
              }
            } else {
              $(this).parents()
                .filter(function () {
                  if ($(this).data('id-product')) {
                    data = {
                      'event': 'add-to-cart',
                      'product': [{
                        'product_id': $(this).data('id-product'),
                        'product_name': $(this).data('product-name'),
                        'product_category': $(this).data('product-category'),
                        'product_brand': $(this).data('product-brand'),
                        'product_price_ati': $(this).data('product-price-ati'),
                        'product_price_tf': $(this).data('product-price-tf')
                      }]
                    }
                  }
                })
                .eq(0)
            }

            console.log('add-to-cart', data.product[0])
            window.dataLayer.push(data)
          }
        )
      }
    )

    let selectorRemoveFromCart = 'a.remove-from-cart'

    waitForEl(selectorRemoveFromCart, function () {
      $(document).on('click touchend', selectorRemoveFromCart, function () {
        $(this).parents()
          .filter(function () {
            if ($(this).data('id-product')) {
              let data = {
                'event': 'remove-from-cart',
                'product': [{
                  'product_id': $(this).data('id-product'),
                  'product_name': $(this).data('product-name'),
                  'product_category': $(this).data('product-category'),
                  'product_brand': $(this).data('product-brand'),
                  'product_price_ati': $(this).data('product-price-ati'),
                  'product_price_tf': $(this).data('product-price-tf')
                }]
              }

              console.log('remove-from-cart', data.product[0])
              window.dataLayer.push(data)
            }
          })
          .eq(0)
      })
    })

    let selectorSave = '.orejime-Modal-saveButton'
    let selectorAccept = '.orejime-Notice-saveButton'
    let selectorDeny = '.orejime-Notice-declineButton'

    waitForEl(selectorSave, function () {
      $(document).on('click touchend', selectorSave, function () {
        let objectSave = {
          'event': 'gdpr-close',
          'button': 'sauvegarder'
        }

        console.log(objectSave)
        window.dataLayer.push(objectSave)
      })
    })

    waitForEl(selectorAccept, function () {
      $(document).on('click touchend', selectorAccept, function () {
        let objectAccept = {
          'event': 'gdpr-close',
          'button': 'accepter'
        }

        console.log(objectAccept)
        window.dataLayer.push(objectAccept)
      })
    })
    waitForEl(selectorDeny, function () {
      $(document).on('click touchend', selectorDeny, function () {
        let objectDeny = {
          'event': 'gdpr-close',
          'button': 'refuser'
        }

        console.log(objectDeny)
        window.dataLayer.push(objectDeny)
      })
    })
  }
}

/**
 * Module export
 */
export default commonConfiguration
