import 'slick-carousel'
import events from '~/utils/events'
import { SLICK, EVENTS } from '~/config/constant'

let homeConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'henaff-index',

  /**
   * Mixins
   * @type {Object}
   */
  mixins: {
    manufacturers: [],
    categories: [],
    events
  },

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED HOME')

    this.initManufacturerSlider()
    this.initCategoriesSlider()
    this.attachGlobalEventHandlers()
  },

  /**
   * Home event handler
   * @void
   */
  attachGlobalEventHandlers () {
    const HOME = this.data().component

    if (HOME.length > 0) {
      this.offsetBoutiqueBloc()
    }

    // slider manufacturers && categories refresh
    $(window).on(
      'load',
      () => {
        if (this.mixins.manufacturers.length > 0) {
          this.mixins.manufacturers.slick('refresh')
        }

        if (this.mixins.categories.length > 0) {
          this.mixins.categories.slick('refresh')
        }
      }
    )
  },

  /**
   * Init manufacturer slide
   * @void
   */
  initManufacturerSlider () {
    const HOME = this.data().component
    const BRAND_SLIDE = HOME.find('.home-manufacturer-slide')
    let _slickConfig = {}

    Object.assign(_slickConfig, SLICK)

    if (BRAND_SLIDE.length > 0) {
      this.mixins.manufacturers = BRAND_SLIDE.find('.slider-wrapper')
      _slickConfig.slidesToShow = 5

      this.mixins.manufacturers.not('.slick-initialized').slick(_slickConfig)
    }
  },

  /**
   * Init categories slide
   * @void
   */
  initCategoriesSlider () {
    const HOME = this.data().component
    const CATEGORIES_SLIDE = HOME.find('.home-categories')
    let _slickConfig = {}

    Object.assign(_slickConfig, SLICK)

    if (CATEGORIES_SLIDE) {
      this.mixins.categories = CATEGORIES_SLIDE.find('.slider-wrapper')

      _slickConfig.slidesToShow = 3
      _slickConfig.arrow = false
      _slickConfig.responsive = [
        {
          breakpoint: 992,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            arrows: false,
            centerMode: true,
            centerPadding: '30px'
          }
        }
      ]

      this.mixins.categories.not('.slick-initialized').slick(_slickConfig)
    }
  },

  /**
   * Section boutique layer offset on windows resize
   * @void
   */
  offsetBoutiqueBloc () {
    const HOME = this.data().component
    const BOUTIQUE = HOME.find('.home-boutique')
    const CONTAINER = BOUTIQUE.find('.container')
    const LAYER_FIRST = BOUTIQUE.find('.layer-first')
    const LAYER_SECOND = BOUTIQUE.find('.layer-second')

    if (BOUTIQUE.length > 0) {
      $(window).on(
        EVENTS.WINDOW_RESIZE,
        () => {
          let _width = this.mixins.events.screen.width
          let _containerWidth = CONTAINER.get(0).clientWidth
          let _offset = 0

          if (_width >= 1600) {
            _offset = '-17%'
          } else {
            _offset = `${(_width - _containerWidth) / -2}px`
          }

          LAYER_FIRST.find('.image-wrapper').css('margin-left', _offset)
          LAYER_SECOND.find('.image-wrapper').css('margin-right', _offset)
        }
      )
    }
  }
}

/**
 * Module export
 */
export default homeConfiguration
