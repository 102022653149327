import 'slick-carousel'
import { SLICK } from '~/config/constant'

let blockProductSlideConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'block-product-slide',

  /**
   * Mixins
   * @type {Object}
   */
  mixins: { slider: [] },

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED BLOCK_PRODUCT_SLIDE')

    this.initCarousel()
    this.attachGlobalEventHandlers()
  },

  /**
   * Product slide event handler
   * @void
   */
  attachGlobalEventHandlers () {
    $(window).on(
      'load',
      () => {
        if (this.mixins.slider.length > 0) {
          this.mixins.slider.slick('refresh')
        }
      }
    )
  },

  /**
   * Init Slick carrousel
   * @void
   */
  initCarousel () {
    const PRODUCT_SLIDE = this.data().component

    if (PRODUCT_SLIDE.length > 0) {
      this.mixins.slider = PRODUCT_SLIDE.find('.slider-wrapper')

      this.mixins.slider.not('.slick-initialized').slick(SLICK)
    }
  }
}

/**
 * Module export
 */
export default blockProductSlideConfiguration
