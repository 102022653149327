import events from '~/utils/events'

let faqsConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'faqs',

  /**
  /**
   * Mixins
   * @type {Object}
   */
  mixins: events,

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $('.henaff-module-advancefaqs-faqs') }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED FAQS')

    this.attachGlobalEventHandlers()
  },

  /**
   * Footer event handler
   * @void
   */
  attachGlobalEventHandlers () {
    const BODY = this.data().component
    const ANCHOR_SELECT = BODY.find('.select-faq > select')

    ANCHOR_SELECT.on(
      'change',
      event => {
        let _hash = event.currentTarget.value

        this.mixins.smoothScroll(_hash)
      }
    )
  }
}

/**
 * Module export
 */
export default faqsConfiguration
