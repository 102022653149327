import 'slick-carousel'

let homeSliderConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'home-slider',

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED HOME_SLIDER')

    this.initCarousel()
  },

  /**
   * Init Slick carrousel
   * @void
   */
  initCarousel () {
    const HOME_SLIDER = this.data().component

    if (HOME_SLIDER.length > 0) {
      const SLIDER = HOME_SLIDER.find('.slider-wrapper')
      let _slickCOnfig = {
        speed: HOME_SLIDER.data('interval'),
        pauseOnHover: HOME_SLIDER.data('pause').length > 0,
        infinite: HOME_SLIDER.data('wrap'),
        autoplay: true,
        arrows: false,
        dots: true,
        rows: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'linear',
        appendDots: HOME_SLIDER.find('.pagination')
      }

      SLIDER.slick(_slickCOnfig)
    }
  }
}

/**
 * Module export
 */
export default homeSliderConfiguration
