let footerConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'main-footer',

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED FOOTER')

    this.attachGlobalEventHandlers()
  },

  /**
   * Footer event handler
   * @void
   */
  attachGlobalEventHandlers () {
    let footerLinks = document.querySelectorAll('.footer__links ul')
    const maxHeight = 291
    const defaultLinkNbr = 15

    if (footerLinks.length > (defaultLinkNbr * 2) - (defaultLinkNbr / 2)) {
      document.querySelector('.footer__links').style.maxHeight = (maxHeight * 2) - (maxHeight / 2) + 'px'
    }
    if (footerLinks.length > defaultLinkNbr) {
      document.querySelector('.footer__links').style.maxHeight = maxHeight * 2 + 'px'
    }
  }
}

/**
 * Module export
 */
export default footerConfiguration
