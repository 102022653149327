/**
 * App main javascript
 *
 * here we can define our needs
 */
import EventEmitter from 'events'
import { APP, EVENTS } from '~/config/constant'
import Router from '~/utils/router'
import henaffCommon from '~/utils/common'
import mainHeader from '~/components/header'
import henaffIndex from '~/components/home'
import homeSlider from '~/components/homeslider'
import video from '~/components/video'
import form from '~/components/form'
import faqs from '~/components/faqs'
import socialSharing from '~/components/social-sharing'
import messagesClient from '~/components/messages'
import blockCart from '~/components/block-cart'
import blockProductSlide from '~/components/block-product-slide'
import productDetail from '~/components/product'
import cartContent from '~/components/cart'
import checkoutContent from '~/components/checkout'
import productList from '~/components/product-list'
import mainFooter from '~/components/footer'

/**
 * Create instance
 */
let buildApp = () => {
  console.log(`%c*** ${APP.NAME} ***`, 'color: red; font-weight: bold')

  let router = new Router({
    henaffCommon,
    henaffIndex,
    mainHeader,
    homeSlider,
    video,
    form,
    faqs,
    socialSharing,
    messagesClient,
    productDetail,
    blockCart,
    blockProductSlide,
    cartContent,
    checkoutContent,
    productList,
    mainFooter
  })

  /**
   * Load event
   */
  router.loadEvents()
}

/**
 * Inherit EventEmitter
 */
for (let i in EventEmitter.prototype) {
  if (typeof prestashop[i] === 'undefined' && typeof EventEmitter.prototype[i] !== 'undefined') {
    prestashop[i] = EventEmitter.prototype[i]
  }
}

/**
 * Hot reload
 * Accept update
 */
if (module.hot) {
  module.hot.accept()

  if (window.henaffInstance) {
    console.info('%c-- %s --', 'color: green; font-weight: bold;', '*** RELOADING LOCATION ***')
    window.location.reload()
  }
}

/*
 * Set app ready
 */
$(window).on(
  'load',
  () => {
    document.body.classList.add(EVENTS.APP_READY)
  }
)

/**
 * Start build app on window load
 */
$(document).ready(() => buildApp())
