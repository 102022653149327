import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/modal'
import 'bootstrap-touchspin'
import 'slick-carousel'
import events from '~/utils/events'
import { EVENTS } from '~/config/constant'

let productDetailConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'product-detail',

  /**
   * Mixins
   * @type {Object}
   */
  mixins: events,

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED PRODUCT_DETAIL')

    this.attachGlobalEventHandlers()
    this.createProductSlideSync()
    this.createModalSlideSync()
    this.createProductTabs()
    this.createProductSpin()
    this.prestashopEventHandler()
  },

  /**
   * Product page event handler
   * @void
   */
  attachGlobalEventHandlers () {
    const PRODUCT_DETAIL = this.data().component
    const EXCERPT = PRODUCT_DETAIL.find('.product-short-description')
    let _togglerWrapper = PRODUCT_DETAIL.find('.social-sharing')
    let _toggler = _togglerWrapper.find('.share-toggler')
    let _shareIcons = _togglerWrapper.find('.share-icons')

    // toggler for social share burron
    _toggler.on(
      'mouseenter',
      () => {
        _shareIcons.addClass('toggle')
      }
    )

    _togglerWrapper.on(
      'mouseleave',
      () => {
        _shareIcons.removeClass('toggle')
      }
    )

    // Short description readmore
    $(window).on(
      EVENTS.WINDOW_RESIZE,
      () => {
        if (this.mixins.screen.width <= 768) {
          this.mixins.readmoreText(EXCERPT)
        } else {
          this.mixins.readmoreText(EXCERPT, true)
        }
      }
    )

    // windows on load function
    $(window).on(
      'load',
      () => {
        this.alcoholFeatures()
      }
    )

    $('#accept-major').on(
      'click',
      event => {
        event.preventDefault()

        this.accept_major()
      }
    )

    $("#alcohol-modal input[name='year']").on(
      'change',
      () => {
        let checkedMajor = $("#alcohol-modal input[name='year']:checked").val()

        if (checkedMajor) {
          $('#accept-major').show()
        } else {
          $('#accept-major').hide()
        }
      })
  },

  /**
   * Prestashop evenet handler on update product
   * @void
   */
  prestashopEventHandler () {
    const PRODUCT_DETAIL = this.data().component
    const INFO_ACCORDION = PRODUCT_DETAIL.find('.product-info-accordion')

    prestashop.on(
      'updatedProduct',
      event => {
        if (event && event.product_minimal_quantity) {
          const MINIMAL_PRODUCT_QUANTITY = parseInt(event.product_minimal_quantity, 10)
          let _quantityInput = PRODUCT_DETAIL.find('#quantity_wanted')

          // @see http://www.virtuosoft.eu/code/bootstrap-touchspin/ about Bootstrap TouchSpin
          _quantityInput.trigger('touchspin.updatesettings', { min: MINIMAL_PRODUCT_QUANTITY })
        }

        let _productImageModal = PRODUCT_DETAIL.find('#product-modal')
        let _listeIngredients = INFO_ACCORDION.find('.liste-ingredients .accordion-content')
        let _infoNutritionnelles = INFO_ACCORDION.find('.information-nutritionnelles .accordion-content')

        _productImageModal.replaceWith(event.product_images_modal)
        _listeIngredients.html(event.custom_liste_ingredients)
        _infoNutritionnelles.html(event.info_nutritionnelles)

        // create product slide sync
        this.createProductSlideSync()
        this.createModalSlideSync()
      }
    )
  },

  /**
   * init slide sync
   * @return {[type]} [description]
   */
  slideSync (_coverWrapper, _slider, _slideToShow) {
    let _slickCOnfig = {
      speed: 500,
      infinite: false,
      dots: false,
      slidesToShow: _slideToShow,
      slidesToScroll: 1
    }

    _slider.not('.slick-initialized').slick(_slickCOnfig)

    let _thumb = $(_slider).find('.thumb')

    _thumb.on(
      'click',
      event => {
        let _cover = _coverWrapper.find('.product-cover .image img')
        let _image = $(event.currentTarget)
        let _src = '<img class="cover" src="' +
          _image.data('image-src') +
          '" srcset="' +
          _image.data('image-srcset') +
          '" alt="' +
          _image.get(0).alt +
          '" title="' +
          _image.get(0).title + '" />'

        _cover.replaceWith($(_src))
      }
    )
  },

  /**
   * Create product slide sync
   * @void
   */
  createProductSlideSync () {
    const PRODUCT_DETAIL = this.data().component
    let _thumbWrapper = PRODUCT_DETAIL.find('.product-thumbnails')
    let _slider = _thumbWrapper.find('ul.thumb-page')

    if (_thumbWrapper.length > 0) {
      this.slideSync(PRODUCT_DETAIL, _slider, 3)
    }
  },

  /**
   * Create product modal slide sync
   * @void
   */
  createModalSlideSync () {
    $('#product-modal').on(
      'shown.bs.modal',
      event => {
        const MODAL = $(event.currentTarget)
        let _thumbWrapper = MODAL.find('.product-thumbnails')
        let _slider = _thumbWrapper.find('ul.thumb-modal')

        if (_thumbWrapper.length > 0) {
          this.slideSync(MODAL, _slider, 3)
        }
      }
    )
  },

  /**
   * Create quantity touchspin
   * @void
   */
  createProductSpin () {
    const PRODUCT_DETAIL = this.data().component
    const QUANTITY_INPUT = PRODUCT_DETAIL.find('#quantity_wanted')

    if (QUANTITY_INPUT.length > 0) {
      let _inputConfig = {
        buttondown_class: 'btn js-touchspin',
        buttonup_class: 'btn js-touchspin',
        min: parseInt(QUANTITY_INPUT.attr('min'), 10),
        max: 1000000
      }

      QUANTITY_INPUT.TouchSpin(_inputConfig)

      $('body').on(
        'change keyup',
        '#quantity_wanted',
        event => {
          $(event.currentTarget).trigger('touchspin.stopspin')

          prestashop.emit(
            'updateProduct',
            {
              eventType: 'updatedProductQuantity',
              event: event
            }
          )
        }
      )
    }
  },

  /**
   * Create product Tabs
   */
  createProductTabs () {
    const PRODUCT_DETAIL = this.data().component
    const PRODUCT_TABS = PRODUCT_DETAIL.find('.product-tabs')
    const MOBILE_TABS = PRODUCT_TABS.find('.product-tabs-mobile')
    const DESKTOP_TABS = PRODUCT_TABS.find('.product-tabs-desktop')
    let _accordionToggler = MOBILE_TABS.find('.accordion-toggler')
    let _tabToggler = DESKTOP_TABS.find('.tab-toggler')

    if (DESKTOP_TABS) {
      let _active = _tabToggler.get(0)
      let _activeTarget = $(_active).data('target')

      $(_active).addClass('active')
      $(_activeTarget).addClass('show active')

      _tabToggler.on(
        'click',
        event => {
          event.preventDefault()
          let _target = event.currentTarget

          $(_target).tab('show')
        }
      )
    }

    if (MOBILE_TABS) {
      _accordionToggler.each(
        (_i, _el) => {
          let _target = $(_el).data('target')

          $(_el).on(
            'click',
            event => {
              event.preventDefault()

              MOBILE_TABS.find(_target).collapse({ parent: MOBILE_TABS })
              MOBILE_TABS.find(_target).collapse('toggle')
            }
          )

          MOBILE_TABS.find(_target).on(
            'show.bs.collapse',
            () => {
              $(_el).attr('aria-expanded', 'true')
            }
          )

          MOBILE_TABS.find(_target).on(
            'shown.bs.collapse',
            () => {
              $('.slider-wrapper').slick('refresh')
            }
          )

          MOBILE_TABS.find(_target).on(
            'hide.bs.collapse',
            () => {
              $(_el).attr('aria-expanded', 'false')
            }
          )
        }
      )
    }
  },

  /**
   * link product variant event handler
   */
  /* productVariantsEvent () {
    const PRODUCT_DETAIL = this.data().component
    const VARIANTS_INFO = PRODUCT_DETAIL.find('.product-variants-info')
    let _variants = VARIANTS_INFO.find('.variants .variants-item a')

    _variants.on(
      'click',
      event => {
        event.preventDefault()

        let _group = $(event.currentTarget)

        console.log(_group)
        // let _selectTarget = $(_group)
      }
    )
  }, */

  alcoholFeatures () {
    const MODAL = $('#alcohol-modal')
    let _cookieAlcohol = this.mixins.getCookie('accepted_major')
    let _isMajor = Object.keys(_cookieAlcohol).length === 0 && _cookieAlcohol.constructor === Object

    if (MODAL && _isMajor) {
      MODAL.modal({
        show: true,
        backdrop: 'static'
      })
    }
  },

  accept_major () {
    const MODAL = $('#alcohol-modal')
    let _checkedMajor = MODAL.find("input[name='year']:checked").val()

    this.mixins.setCookie('accepted_major', _checkedMajor, 365)

    MODAL.modal('hide')
  }
}

/**
 * Module export
 */
export default productDetailConfiguration
