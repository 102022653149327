import 'custom-event-polyfill'
import { camelCase } from 'camel-case'
import 'events'

export default class Router {
  /**
   * Router constructor
   * @param {Object} routes
   */
  constructor (routes) {
    this.routes = routes
  }
  /**
   * Fire Router events
   * @param {string} route DOM-based route derived from element classes (`<section class="...">`)
   * @param {string} [event] Events on the route. By default, `created` and `mounted` events are called.
   * @param {string} [arg] Any custom argument to be passed to the event.
   */
  fireEvents (route, event = 'created', arg) {
    document.dispatchEvent(
      new CustomEvent(
        'routed',
        {
          bubbles: true,
          detail: {
            route,
            fn: event
          }
        }
      )
    )

    const FIRE = route !== '' && this.routes[route] && typeof this.routes[route][event] === 'function'

    if (FIRE) {
      this.routes[route][event](arg)
    }
  }

  /**
   * load and fire Router events
   * @void
   */
  loadEvents () {
    Object.keys(this.routes)
      .forEach(
        route => {
          let classname = camelCase(this.routes[route].name)
          let mixins = this.routes[route].mixins || {}

          if (typeof mixins.created === 'function') {
            mixins.created()
          }

          this.fireEvents(classname)
          this.fireEvents(classname, 'mounted')
        }
      )
  }
}
