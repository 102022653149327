import 'bootstrap/js/dist/tooltip'
import events from '~/utils/events'
import { camelCase } from 'camel-case'

let cartListConfiguration = {
  /**
   * Component name
   * @type {String}
   */
  name: 'cart-content',

  /**
   * Mixins
   * @type {Object}
   */
  mixins: {
    events: events,
    promises: [],
    hasError: false,
    isUpdateOperation: false,
    errorMsg: ''
  },

  /**
   * Data
   * @type {Object}
   */
  data () {
    return { component: $(`.${this.name}`) }
  },

  /**
   * Create components
   * @type {String}
   */
  created () {
    console.log('CREATED CART')

    prestashop.cart = prestashop.cart || {}
    prestashop.cart.active_inputs = null

    this.createProductSpin()
    this.attachGlobalEventHandlers()
  },

  /**
   * Cart page event handler
   * @void
   */
  attachGlobalEventHandlers () {
    const CART = this.data().component
    const BODY = $('body')
    const TOOLTIP = CART.find('[data-toggle="tooltip"]')
    const CART_BODY = CART.find('.cart-body')
    let _isCart = BODY.hasClass('henaff-cart')

    // init tooltip
    TOOLTIP.tooltip()

    // Click on data-link action
    BODY.on(
      'click',
      '[data-link-action="delete-from-cart"], [data-link-action="remove-voucher"]',
      event => {
        event.preventDefault()

        this.updateShoppingCart(event)
      }
    )

    // Touchspin event
    BODY.on(
      'touchspin.on.startdownspin',
      'input[name="product-quantity-spin"]',
      event => {
        event.preventDefault()

        this.updateShoppingCart(event)
      }
    )

    BODY.on(
      'touchspin.on.startupspin',
      'input[name="product-quantity-spin"]',
      event => {
        event.preventDefault()

        this.updateShoppingCart(event)
      }
    )

    BODY.on(
      'click',
      '.cart-message .message > button',
      () => {
        const CART_MESSAGE = CART_BODY.find('.cart-message')
        let _message = CART_MESSAGE.find('.message')

        _message.fadeOut()
      }
    )

    if (_isCart) {
      BODY.on(
        'click touchend',
        'button.add-to-cart',
        () => {
          CART_BODY.addClass('is--loading')
          this.mixins.events.smoothScroll('#main')
        }
      )
    }

    // Shopping cart update
    prestashop.on(
      'updatedCart',
      event => {
        const CART_MESSAGE = CART_BODY.find('.cart-message')
        const CART_PROMO_BLOCK = CART_BODY.find('.block-promo-checkout')

        this.createProductSpin()

        CART_MESSAGE.replaceWith(event.resp.cart_message_shipping_free)
        CART_PROMO_BLOCK.html(event.resp.cart_block_promo_checkout)
        CART_BODY.removeClass('is--loading')
      }
    )

    prestashop.on(
      'handleError',
      () => {
        CART_BODY.removeClass('is--loading')
      }
    )
  },

  /**
   * Abort previous ajax request
   * @void
   */
  abortPreviousRequests () {
    while (this.mixins.promises.length > 0) {
      let _promise = this.mixins.promises.pop()

      _promise.abort()
    }
  },

  /**
   * Create quantity touchspin
   * @void
   */
  createProductSpin () {
    const CART = this.data().component
    const QUANTITY_INPUT = CART.find('input[name="product-quantity-spin"]')

    if (QUANTITY_INPUT.length > 0) {
      let _inputConfig = {
        buttondown_class: 'btn js-touchspin',
        buttonup_class: 'btn js-touchspin',
        min: parseInt(QUANTITY_INPUT.attr('min'), 10),
        max: 1000000
      }

      QUANTITY_INPUT.TouchSpin(_inputConfig)
    }

    this.switchErrorStat()
  },

  /**
   * Parse blockcart event action
   * @return {Object}
   */
  parseBlockCartAction (_target, _namespace) {
    let _blockCartAction = {}

    switch (_namespace) {
      case 'on.startupspin':
        _blockCartAction = {
          url: _target.data('up-url'),
          type: 'increaseProductQuantity'
        }
        break

      case 'on.startdownspin':
        _blockCartAction = {
          url: _target.data('down-url'),
          type: 'decreaseProductQuantity'
        }
        break

      default:
        _blockCartAction = {
          url: _target.attr('href'),
          type: camelCase(_target.data('link-action'))
        }
        break
    }

    return _blockCartAction
  },

  /**
   * Blockcart event handler
   * @param  {Event} event
   * @void
   */
  updateShoppingCart (_event) {
    const TARGET = $(_event.currentTarget)
    const QUERY = {
      ajax: '1',
      action: 'update'
    }

    let _cartAction = this.parseBlockCartAction(TARGET, _event.namespace)

    if (typeof _cartAction === 'undefined') {
      return
    }

    this.abortPreviousRequests()

    $.ajax(
      {
        url: _cartAction.url,
        method: 'POST',
        data: QUERY,
        dataType: 'json',
        beforeSend: jqXHR => {
          this.mixins.promises.push(jqXHR)
        }
      }
    )
      .then(
        resp => {
          // check if ajax request hasError
          this.checkUpdateOpertation(resp)

          TARGET.val(resp.quantity)

          prestashop.emit(
            'updateCart',
            {
              reason: {
                idProduct: resp.id_product,
                idProductAttribute: resp.id_product_attribute,
                idCustomization: resp.id_customization,
                cart: resp.cart
              },
              resp: resp
            }
          )
        }
      )
      .fail(
        resp => {
          prestashop.emit(
            'handleError',
            {
              eventType: 'updateShoppingCart',
              resp: resp,
              cartAction: _cartAction.type
            }
          )
        }
      )
  },

  /**
   * Check if update operation on cart has error
   * @param  {Object} _response
   * @void
   */
  checkUpdateOpertation (_response) {
    let _errors = _response.errors || ''

    this.mixins.hasError = Object.prototype.hasOwnProperty.call(_response, 'hasError')

    // 1.7.2.x returns errors as string, 1.7.3.x returns array
    if (_errors instanceof Array) {
      this.mixins.errorMsg = _errors.join(' ')
    } else {
      this.mixins.errorMsg = _errors
    }

    this.mixins.isUpdateOperation = true
  },

  /**
   * Switch error state after cart update operation
   * @void
   */
  switchErrorStat () {
    const CART = this.data().component
    const CHECKOUT_BTN = CART.find('.checkout a')
    const NOTIFICATIONS = $('#notifications')

    if (NOTIFICATIONS.find('article.alert-danger').length || (this.mixins.errorMsg !== '' && !this.mixins.hasError)) {
      CHECKOUT_BTN.addClass('disabled')
    }

    if (this.mixins.errorMsg !== '') {
      let _errorComponent = ` <article class="alert alert-danger" role="alert" data-alert="danger"><ul><li>${this.mixins.errorMsg}</li></ul></article>`

      NOTIFICATIONS.find('.container').html(_errorComponent)

      this.mixins.errorMsg = ''
      this.mixins.isUpdateOperation = false

      if (this.mixins.hasError) {
        // if hasError is true, quantity was not updated : allow checkout
        CHECKOUT_BTN.removeClass('disabled')
      }
    } else if (!this.mixins.hasError && this.mixins.errorMsg) {
      this.mixins.hasError = false
      this.mixins.isUpdateOperation = false

      NOTIFICATIONS.find('.container').html('')
      CHECKOUT_BTN.removeClass('disabled')
    }
  }
}

/**
 * Module export
 */
export default cartListConfiguration
